import Box, { BoxProps } from '@mui/material/Box';
import { m } from 'framer-motion';

import Logo from '../logo';

export default function LoadingSplashScreen({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        right: 0,
        width: 1,
        bottom: 0,
        height: 1,
        zIndex: 9998,
        display: 'flex',
        position: 'fixed',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
        ...sx,
      }}
      {...other}
    >
      <m.div
        initial={{ rotateY: 0 }}
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ['25%', '25%', '50%', '50%', '25%'],
        }}
        transition={{
          duration: 3.2,
          ease: 'linear',
          repeatDelay: 0,
          repeat: Infinity,
        }}
      >
        <Logo disabledLink sx={{ width: 100, height: 100 }} />
      </m.div>
    </Box>
  );
}
